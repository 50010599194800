import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { UserProvider } from "./components/UserContext";
import { UserDataProvider } from "./components/UserDataContext";
import { SearchProvider } from "./components/SearchContext";
import Login from "./pages/Login";
import NoPermission from "./pages/NoPermission";

import "./App.css";

export const API_URL = "http://185.180.205.154/pzitb/api";
export const APP_NAME = "PZITB";
export const windowWidth = 768;
export const marginBottom = "2%";

function App() {
  //const baseUrl = process.env.REACT_APP_BASE_URL || "/pzitb";

  return (
    <>
      <UserDataProvider>
        <UserProvider>
          <SearchProvider>
            {/* <BrowserRouter basename={baseUrl}> */}
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/nopermission" element={<NoPermission />} />
              </Routes>
            </BrowserRouter>
          </SearchProvider>
        </UserProvider>
      </UserDataProvider>
    </>
  );
}

export default App;
