import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

export default function Alerts(data) {
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity={data.data.severity}>
        <AlertTitle>{data.data.title}</AlertTitle>
        {data.data.info}
      </Alert>
    </Stack>
  );
}
