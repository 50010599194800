import axios from "axios";
import Cookies from "js-cookie";
import { API_URL } from "../App";

export const fetchUserData = async () => {
  const sessionId = Cookies.get("pzitbkatowice");

  try {
    const response = await axios.post(
      API_URL + "/session",
      { param: sessionId },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.data[0].error_msg) {
      throw new Error(response.data[0].error_msg);
    }

    return {
      userId: response.data[0].userId,
      user: response.data[0].user,
      admin: response.data[0].admin,
      fullName: response.data[0].fullName,
      grpId: response.data[0].grpId,
      grpAdmin: response.data[0].grpAdmin,
      cookie: response.data[0].cookie,
      expire: response.data[0].expire,
      is_active: response.data[0].is_active,
      helper: response.data[0].helper,
      grpAdminFullName: response.data[1].grpAdminFullName,
    };
  } catch (error) {
    //console.log("zaloguj się", error.code);
    return null;
  }
};
