import VN from "../components/VN";
import { fetchUserData } from "../components/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NoPermission() {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const userDataAnswer = await fetchUserData();
        if (userDataAnswer && userDataAnswer.userId) {
          setUserData(userDataAnswer);
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error during user verification:", error);
        navigate("/login");
      }
    };
    verifyUser();
  }, [navigate]);

  return (
    <div className="bodyReminder">
      {userData && userData.userId > 0 && <VN userData={userData} />}

      <h2>Brak uprawnień</h2>
    </div>
  );
}
