import React from "react";

const DateComponent = () => {
  const today = new Date();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedDate = today.toLocaleDateString("pl-PL", options);

  return (
    <div>
      <h5>{formattedDate}</h5>
    </div>
  );
};

export default DateComponent;
