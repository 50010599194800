import { useState, useEffect, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from "react-router-dom";
import { fetchUserData } from "../components/auth";
import { UserDataContext } from "./UserDataContext";
import { SearchContext } from "./SearchContext";
import { API_URL } from "../App";
import axios from "axios";
import Cookies from "js-cookie";
import Person3SharpIcon from "@mui/icons-material/Person3Sharp";
import DateComponent from "./DateComponent";
import { marginBottom } from "../App";
import { Row, Col } from "react-bootstrap";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function VN() {
  const { searchValue, setSearchValue } = useContext(SearchContext);
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(UserDataContext);
  const [expire, setExpire] = useState(null);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);

  const logout = () => {
    const handleLogout = () => {
      Cookies.remove("pzitbkatowice");
      setUserData({
        ...userData,
        userId: 0,
      });

      try {
        const response = axios.post(
          API_URL + "/logout",
          { param: userData.cookie },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        //setError(error.message);
        console.error(error);
      }
      navigate(0);
    };

    handleLogout(navigate, userData.cookie);
  };

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const userDataAnswer = await fetchUserData();
        if (userDataAnswer && userDataAnswer.userId) {
          setUserData(userDataAnswer);
          setExpire(userDataAnswer.expire);
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error during user verification:", error);
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };
    verifyUser();
  }, [navigate, setUserData]);

  useEffect(() => {
    if (expire) {
      const compareDateWithToday = (inputDate) => {
        const today = new Date();
        const input = new Date(inputDate);
        today.setHours(0, 0, 0, 0);
        input.setHours(0, 0, 0, 0);
        if (input < today) {
          navigate("/expired");
        }
      };
      compareDateWithToday(expire);
    }
  }, [expire]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${API_URL}/getcount/${userData.cookie}/${userData.userId}`;
      try {
        const response = await axios.get(url);
        setCount(response.data);
      } catch (error) {
        console.error(
          "Błąd podczas pobierania danych. Najprawdopodobniej utracono połączenie z internetem.",
          error
        );
      }
    };

    if (userData) {
      fetchData();
      const intervalId = setInterval(fetchData, 1000);
      return () => clearInterval(intervalId);
    }
  }, [userData]);
  //useEffect(() => {
  const getInitials = (fullName) => {
    if (userData && userData.userId > 0 && fullName) {
      const nameArray = fullName.trim().split(" ");
      if (nameArray.length === 1) {
        return nameArray[0].charAt(0).toUpperCase();
      }
      const initials =
        nameArray[0].charAt(0).toUpperCase() +
        nameArray[1].charAt(0).toUpperCase();
      return initials;
    } else return "NN";
  };
  //}, [userData]);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <Row style={{ marginBottom }}>
      <AppBar position="static">
        <Toolbar disableGutters>
          {!loading && userData && (
            <Person3SharpIcon
              sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 400,
              fontSize: "1.0rem",
              letterSpacing: ".1rem",
              color: "yellow",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            {!loading && userData ? userData.fullName : ""}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {!loading && userData && (
                <MenuItem key="1" onClick={handleCloseNavMenu}>
                  <Typography
                    onClick={() => navigate("/remindertabs/1")}
                    sx={{ textAlign: "center" }}
                  >
                    PRZYPOMNIENIA
                  </Typography>
                </MenuItem>
              )}
            </Menu>
            {!loading && userData && (
              <Search sx={{ display: { xs: "block", md: "none" } }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Szukaj…"
                  value={searchValue}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => handleSearch(e)}
                />
              </Search>
            )}
          </Box>
          {!loading && userData && (
            <Person3SharpIcon
              sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
            />
          )}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontSize: "1rem",
              fontFamily: "Lato",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "yellow",
              textDecoration: "none",
            }}
          >
            {!loading && userData ? getInitials(userData.fullName) : ""}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {!loading && userData && (
              <Button
                key="1"
                onClick={() => navigate("/remindertabs/1")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                PRZYPOMNIENIA
              </Button>
            )}
          </Box>
          <DateComponent />
          {!loading && userData && (
            <Search sx={{ display: { xs: "none", md: "block" } }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Szukaj…"
                value={searchValue}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => handleSearch(e)}
              />
            </Search>
          )}
          <MenuItem>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={count || 0} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </MenuItem>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Ustawienia">
              <SettingsOutlinedIcon
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
              />
            </Tooltip>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {" "}
              {!loading && userData && userData.grpAdmin === "1" && (
                <MenuItem key="1" onClick={() => navigate("/passchange")}>
                  <Typography sx={{ textAlign: "center" }}>
                    Zmiana hasła
                  </Typography>
                </MenuItem>
              )}
              {!loading && userData && userData.grpAdmin === "1" && (
                <MenuItem key="2" onClick={() => navigate("/groups")}>
                  <Typography sx={{ textAlign: "center" }}>Grupa</Typography>
                </MenuItem>
              )}
              <MenuItem key="3" onClick={logout}>
                <Typography sx={{ textAlign: "center" }}>Wyloguj</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Row>
  );
}
export default VN;
